<template>
    <form ref="form" v-on:keydown.enter="preventEnterKey">
        <teleport to="#filter-icon-teleport">
            <div
                class="search-icon"
                v-if="$isLogin() && isComponentActive && isShowFilterIcon"
                @click="isShowFilter = true"
            />
        </teleport>
        <Modal
            id="component-area"
            v-model="isShowFilter"
            :title="'請輸入查詢條件'"
            :transition="transition"
        >
            <template v-slot:modal-content>
                <div>
                    <div class="filter">
                        <slot name="filters" />
                    </div>
                    <div v-if="hasAdvancedSlot">
                        <div class="hr" />
                        <div
                            class="advanced"
                            @click="isShowAdvanced = !isShowAdvanced"
                        >
                            快速搜尋
                            <div
                                class="toggle-menu-icon"
                                :class="{ active: isShowAdvanced }"
                            />
                        </div>
                        <transition name="dropdown">
                            <div class="filter" v-if="isShowAdvanced">
                                <slot name="advanced" />
                            </div>
                        </transition>
                    </div>
                </div>
            </template>
            <template v-slot:modal-bottom>
                <div class="component-container button-container">
                    <Button
                        buttonWord="重設內容"
                        buttonStyle="grey"
                        @click.prevent="resetFilter"
                    />
                    <Button
                        :buttonWord="buttonWord"
                        :class="{ disabled: isLoading }"
                        buttonStyle="blue"
                        iconClass="big-icon search-icon"
                        @click.prevent="filterData"
                    />
                </div>
            </template>
        </Modal>
    </form>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'FilterModal',
    emits: ['filter', 'reset'],
    components: {
        Modal,
        Button
    },
    props: {
        buttonWord: {
            type: String,
            default: '套用'
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        preventEnterKey: function (event) {
            event.preventDefault()
        },
        filterData: function () {
            if (this.isLoading) return
            this.toggleErrorClass(
                document.querySelectorAll('input:not(.hide)'),
                'remove'
            )
            if (this.$refs.form?.checkValidity()) {
                this.$emit('filter', false)
                if (this.isMobileWidth) {
                    this.isShowFilter = false
                }
            } else {
                this.toggleErrorClass(
                    document.querySelectorAll('input:invalid'),
                    'add'
                )
            }
            this.$setGaEvent('searchButton', 'click-Button')
        },
        resetFilter: function () {
            this.toggleErrorClass(document.querySelectorAll('input'), 'remove')
            this.$emit('reset', false)
            this.$setGaEvent('resetButton', 'click-Button')
        },
        checkClientWidth: function () {
            let currentWidth = document.body.clientWidth
            let isSameWidth = this.perviousWidth === currentWidth
            if (!isSameWidth) {
                this.perviousWidth = currentWidth
                this.isMobileWidth = this.$isMobile()
            }
        },
        toggleErrorClass: function (arr, state) {
            arr.forEach((input) => {
                if (state === 'add') {
                    input.parentNode.classList.add('input-error-ci-style')
                } else {
                    input.parentNode.classList.remove('input-error-ci-style')
                }
            })
        }
    },
    computed: {
        hasAdvancedSlot() {
            return !!this.$slots.advanced
        },
        isShowFilterIcon: function () {
            let isShow = true
            if (this.$route.name === 'TiaInsurance' && this.$route.params.id)
                isShow = false

            return isShow
        }
    },
    watch: {
        isShowAdvanced: function () {
            if (this.isShowAdvanced)
                this.$setGaEvent('advancedOpen', 'open-Advanced')
        },
        isMobileWidth: function () {
            this.transition = ''
            this.$nextTick(() => {
                if (this.isMobileWidth) {
                    this.isShowFilter = false
                } else {
                    this.isShowFilter = true
                }
                this.$nextTick(() => {
                    const body = document.querySelector('body')
                    this.$removeAllClass(body, 'not-scroll')
                    this.transition = 'fade'
                })
            })
        }
    },
    data() {
        return {
            isShowFilter: false,
            isMobileWidth: true,
            isShowAdvanced: false,
            isComponentActive: true,
            perviousWidth: 0,
            transition: 'fade'
        }
    },
    created() {
        window.addEventListener('resize', this.checkClientWidth)
        this.checkClientWidth()
    },
    activated() {
        this.isComponentActive = true
    },
    deactivated() {
        this.isComponentActive = false
    },
    unmounted() {
        window.addEventListener('resize', this.checkClientWidth)
    }
}
</script>

<style lang="scss" scoped>
.search-icon {
    display: none;
    align-items: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    filter: invert(1);
    @media screen and (max-width: 576px) {
        display: block;
    }
}

#component-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    & :deep(.modal-area) {
        overflow-x: hidden;
        overflow-y: auto;
    }
    & :deep(.modal-bottom) {
        position: sticky;
    }

    @media screen and (min-width: 577px) {
        position: relative;
        z-index: 6;
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: 20px;

        :deep(.modal-background) {
            padding: 0;
        }

        & :deep(.modal-area) {
            width: 100%;
            padding: 30px;
            margin: 0;
            border-radius: 0;
            overflow: visible;
        }
        & :deep(.modal-title) {
            display: none;
        }

        & :deep(.x-button) {
            display: none;
        }

        & :deep(.modal-content) {
            margin: 0;
            padding: 0;
        }

        & :deep(.modal-bottom) {
            position: relative;
            padding: 0;
            margin-bottom: 0px;
            bottom: 0px;
            z-index: 0;
        }
    }
}

.filter {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(auto-fill, 210px);
    -ms-grid-columns: repeat(auto-fill, 210px);
    justify-content: space-between;
    gap: 0 20px;
    width: 100%;

    @media screen and (max-width: 576px) {
        grid-template-columns: 100%;
        :deep(.input-container) {
            width: 100%;

            &:last-child {
                margin-bottom: 50px;
                .option-container {
                    position: relative;
                    margin-bottom: -30px;
                }
            }
        }
    }
}

.advanced {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
    color: $sixth-black;
    cursor: pointer;

    .toggle-menu-icon {
        height: 10px;
        width: 10px;
        margin-left: 15px;
        transition: transform 0.3s;
        transform: rotate(0deg);
        &.active {
            transform: rotate(90deg);
        }
    }
}

.component-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.button-container {
    justify-content: flex-end;

    @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: center;
    }
}

.filter-tips {
    color: $secondary-grey;
    font-size: 14px;
    margin: 0 0 10px;
    word-break: keep-all;
    :deep(span) {
        color: $primary-red;
    }
    @media screen and (max-width: 576px) {
    }
}
</style>
